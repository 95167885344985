import type { Nullable } from '~/types/helpers'
import type { BookingPriceRequest } from '~/types/rental-api-aliases'
import type { BookingEstimateDestination, BookingEstimateParams, RvUsage } from '~/types'
import type { NuxtError } from '#app'

export type CustomQuoteParams = {
  bookingId: number
  renterId: number
  customTotalRentalAmount?: number
  customTotalAddOns?: number
  customDeliveryAmountCents?: number
}

export default function useBookingEstimate() {
  const bookingEstimateParams = useBookingEstimateParams()
  const bookingEstimatePending = useBookingEstimatePending()
  const bookingEstimateError = useBookingEstimateError()
  const bookingEstimateData = useBookingEstimateData()
  const { $rentals } = useNuxtApp()
  const {
    rvId,
    rvIsInsured,
    rvIsStatic,
    rvOwnerId,
    rvGuests,
    rvMinimumTripLength,
    rvAllowPets,
  } = useRvDetails()

  const hasDateRange = computed(() => Boolean(bookingEstimateParams.value.dateStart && bookingEstimateParams.value.dateEnd))
  const hasIncompleteDateRange = computed(() => !bookingEstimateParams.value.dateStart || !bookingEstimateParams.value.dateEnd)
  const isBookable = computed(() => Boolean(bookingEstimateData.value))

  /**
   * Fetch Booking Price Estimate.
   */
  async function fetchBookingEstimate(customQuoteParams?: CustomQuoteParams) {
    if (bookingEstimatePending.value || !rvId.value || !rvOwnerId.value) {
      return {
        bookingEstimateData,
        bookingEstimateError,
        bookingEstimatePending,
      }
    }

    if (hasIncompleteDateRange.value) {
      bookingEstimateData.value = null
      return {
        bookingEstimateData,
        bookingEstimateError,
        bookingEstimatePending,
      }
    }

    try {
      bookingEstimatePending.value = true

      const protectionLevel = bookingEstimateParams.value.protectionLevel ?? null

      // Set protection level based on RV type if RV is insured and no protection level is set
      if (!protectionLevel && rvIsInsured.value) {
        bookingEstimateParams.value.protectionLevel = rvIsStatic.value ? 'Stationary' : 'Standard'
      }

      // Validate guest count
      if (Number(bookingEstimateParams.value.adults + bookingEstimateParams.value.children) > rvGuests.value) {
        throw createError({
          data: {
            Code: 'INVALID_GUEST_COUNT',
            Message: 'Requested guests count is exceeding RV capacity',
          },
        })
      }

      // Validate minimum trip duration
      const tripDuration = dates(bookingEstimateParams.value.dateEnd).diff(dates(bookingEstimateParams.value.dateStart), 'day')
      if (tripDuration < rvMinimumTripLength.value) {
        throw createError({
          data: {
            Code: 'INVALID_TRIP_DURATION',
            Message: 'Requested trip duration is less than the minimum required',
          },
        })
      }

      // Validate Pets friendly rules
      if (bookingEstimateParams.value.pets && !rvAllowPets.value) {
        throw createError({
          data: {
            Code: 'NO_PETS_ALLOWED',
            Message: 'Requested pets is disallowed by RV rules',
          },
        })
      }

      const params = computed(() => <BookingPriceRequest>({
        DateEnd: bookingEstimateParams.value.dateEnd,
        DateStart: bookingEstimateParams.value.dateStart,
        OwnerId: rvOwnerId.value,
        RVId: rvId.value,
        PlaceOfResidence: 'XY',
        ProtectionLevel: bookingEstimateParams.value.protectionLevel,
        RoadsideAssistanceState: bookingEstimateParams.value.roadsideAssistance,
        ListAddOnPicked: bookingEstimateParams.value.addons,
        Adults: bookingEstimateParams.value.adults,
        Children: bookingEstimateParams.value.children,
        Pets: bookingEstimateParams.value.pets ? 1 : 0,
        RvUsage: bookingEstimateParams.value.rvUsage ?? 'Pickup',
        ShowProtectionPlan: true,

        // Custom Quote
        BookingId: customQuoteParams?.bookingId,
        CustomTotalRentalAmount: customQuoteParams?.customTotalRentalAmount,
        CustomTotalAddOnAmount: customQuoteParams?.customTotalAddOns,
        CustomDeliveryAmountCents: customQuoteParams?.customDeliveryAmountCents,
        RenterId: customQuoteParams?.renterId,
      }))

      if (bookingEstimateParams.value.destination) {
        params.value.Destination = bookingEstimateParams.value.destination.name
        params.value.DestinationLatitude = bookingEstimateParams.value.destination.latitude
        params.value.DestinationLongitude = bookingEstimateParams.value.destination.longitude
        params.value.DestinationTypes = bookingEstimateParams.value.destination.types
      }
      else {
        // Default to Pickup if no destination
        params.value.RvUsage = 'Pickup'
      }

      const data = await $rentals('/api/bookings/get-booking-price', {
        method: 'POST',
        body: params.value,
      })

      bookingEstimateData.value = data
      bookingEstimateError.value = null
    }
    catch (err) {
      bookingEstimateData.value = null
      bookingEstimateError.value = createError(err as NuxtError)
    }
    finally {
      bookingEstimatePending.value = false
    }

    return {
      bookingEstimateData,
      bookingEstimateError,
      bookingEstimatePending,
    }
  }

  /**
   * Update Booking Price parameters.
   */
  function updateDateRange({ dateStart, dateEnd }: { dateStart: Nullable<string>, dateEnd: Nullable<string> }) {
    bookingEstimateParams.value.dateStart = dateStart || ''
    bookingEstimateParams.value.dateEnd = dateEnd || ''
  }

  function updateGuests({ adults = 1, children = 0, pets = false }) {
    bookingEstimateParams.value.adults = adults
    bookingEstimateParams.value.children = children
    bookingEstimateParams.value.pets = pets
  }

  function updateRoadsideAssistance(roadsideAssistance: boolean) {
    bookingEstimateParams.value.roadsideAssistance = roadsideAssistance
  }

  function updateProtectionPlan(protectionPlan: BookingPriceRequest['ProtectionLevel']) {
    bookingEstimateParams.value.protectionLevel = protectionPlan ?? null
  }

  function updateRvUsage(rvUsage: RvUsage) {
    // Should we also check if delivery only ?
    bookingEstimateParams.value.rvUsage = rvUsage
    if (rvIsInsured.value) {
      // Should we also check if delivery only ?
      if (rvIsStatic.value || rvUsage === 'StationaryAfterDelivery') {
        updateProtectionPlan('Stationary')
      }
      else {
        updateProtectionPlan('Standard')
      }
    }
  }

  function updateDestination(destination?: BookingEstimateDestination) {
    bookingEstimateParams.value.destination = destination
  }

  /**
   * Update the addons for the booking.
   * @param addons The list of addon IDs to add to the booking.
   */
  function updateAddOns(addons: number[]) {
    bookingEstimateParams.value.addons = addons.map((addonId) => ({
      Quantity: 1,
      IdInRV: addonId,
    }))
  }

  function updateEstimateParams(params: BookingEstimateParams) {
    bookingEstimateParams.value = params
  }

  // Reset data on RV change
  watch(rvId, () => {
    bookingEstimatePending.value = false
    bookingEstimateError.value = null
    bookingEstimateData.value = null
    bookingEstimateParams.value.addons = []
    // clear destination/delivery?
    // clear protection?
  })

  const financialData = computed(() => getFinancial(bookingEstimateData.value?.Financial))

  const rvSnapshot = computed(() => getRvSnapshot(bookingEstimateData.value?.RVSnapshot))

  return {
    bookingEstimateParams,
    bookingEstimateData,
    bookingEstimatePending,
    bookingEstimateError,
    hasDateRange,
    hasIncompleteDateRange,
    isBookable,
    ...toRefs(toReactive(financialData)),
    ...toRefs(toReactive(rvSnapshot)),
    fetchBookingEstimate,
    updateAddOns,
    updateDateRange,
    updateDestination,
    updateEstimateParams,
    updateGuests,
    updateProtectionPlan,
    updateRoadsideAssistance,
    updateRvUsage,
  }
}
